import { coreApi } from './base'

export const getCountries = async () => {
  try {
    const response = await coreApi.get('/country')
    const data = await response.data
    return { ok: true, data }
  } catch (error) {
    return { ok: false, error: error.response.data }
  }
}

export const getCurrencies = async () => {
  try {
    const response = await coreApi.get('/currency')
    const data = await response.data
    return { ok: true, data }
  } catch (error) {
    return { ok: false, error: error.response.data }
  }
}

export const getLanguages = async () => {
  try {
    const response = await coreApi.get('/language')
    const data = await response.data
    return { ok: true, data }
  } catch (error) {
    return { ok: false, error: error.response.data }
  }
}
