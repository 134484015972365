import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { FormattedMessage, useIntl } from 'gatsby-plugin-intl'
import { useForm } from 'react-hook-form'

import storageService from '../../services/storageService'
import { getCountries } from '../../adapters/common'
import { updateAccount } from '../../adapters/user'

import toast from '../../components/common/toast'

import media from '../../components/css/media'

const SectionHeader = styled.div`
  line-height: 1.4em;
  font-size: 1.412em;
  font-weight: 600;
  margin: 1rem 0 0.8rem 0;
`

const Form = styled.form`
  input[type='submit'] {
    width: 100% !important;
    margin: 0;
  }

  ${media.tablet`
    input[type="submit"] {
      width: 30% !important;
    }
  `};
`

const Row = styled.div.attrs((props) => ({
  flexMobile: props.flexMobile || 'column',
}))`
  display: flex;
  gap: 20px;
  flex-direction: ${(props) => props.flexMobile};

  ${media.tablet`
    flex-direction: row;
  `};
`

const Col = styled.div.attrs((props) => ({
  width: props.width || '50%',
}))`
  display: flex;
  flex-direction: column;
  flex: 1 1 ${(props) => props.width};
`

const ProfileDetails = () => {
  const intl = useIntl()
  const user = storageService.getUser()
  const [phoneCodes, setPhoneCodes] = useState([])

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, touchedFields },
  } = useForm()

  const onSubmit = async (data) => {
    let profile = Object.assign({}, user)

    profile.city = data.city
    profile.postcode = data.postCode
    profile.street = data.address

    profile.mobile_number = data.mobileNumber
    profile.phone_number = data.mobileNumber

    profile.mobile_prefix = data.mobilePrefix
    profile.phone_prefix = data.mobilePrefix

    const response = await updateAccount(profile)
    if (response.ok) {
      storageService.setUser(JSON.stringify(profile))
      toast.success(
        intl.formatMessage({ id: 'account.profile.profileUpdatedSuccessfully' })
      )
    }
  }

  useEffect(() => {
    async function loadCountries() {
      const response = await getCountries()

      if (response.ok) {
        let codes = []
        response.data.data.forEach((c) => {
          if (c.phone_prefix !== '') {
            codes.push(`+${c.phone_prefix}`)
          }
        })
        codes.sort()

        let uniqueCodes = [...new Set(codes)]
        setPhoneCodes(uniqueCodes)
        if (user.mobile_prefix) {
          setValue('mobilePrefix', user.mobile_prefix)
        }
      }
    }

    const fetchData = async () => {
      await loadCountries()
    }
    fetchData()

    // eslint-disable-next-line
  }, [setValue])

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <SectionHeader>
          <FormattedMessage id='userProfile.loginDetails' />
        </SectionHeader>

        <Row>
          <Col>
            <label htmlFor='email'>
              <FormattedMessage id='userProfile.email' />
            </label>
            <input disabled={true} value={user?.email} />
          </Col>
          <Col />
        </Row>

        <SectionHeader>
          <FormattedMessage id='userProfile.personalDetails' />
        </SectionHeader>

        <Row>
          <Col>
            <label htmlFor='name'>
              <FormattedMessage id='userProfile.name' />
            </label>
            <input disabled={true} value={user?.first_name} />
          </Col>
          <Col>
            <label htmlFor='lastname'>
              <FormattedMessage id='userProfile.lastName' />
            </label>
            <input disabled={true} value={user?.last_name} />
          </Col>
        </Row>
        <Row>
          <Col>
            <label htmlFor='dob'>
              <FormattedMessage id='userProfile.dob' />
            </label>
            <Row flexMobile='row'>
              <Col>
                <input
                  disabled={true}
                  value={new Date(user?.birthday).getDate()}
                />
              </Col>
              <Col>
                <input
                  disabled={true}
                  value={new Date(user?.birthday).getMonth() + 1}
                />
              </Col>
              <Col>
                <input
                  disabled={true}
                  value={new Date(user?.birthday).getFullYear()}
                />
              </Col>
            </Row>
          </Col>
          <Col>
            <label htmlFor='mobile'>
              <FormattedMessage id='userProfile.mobileNumber' />
            </label>
            <Row flexMobile='row'>
              <Col width='32%'>
                <select
                  defaultValue={user?.mobile_prefix}
                  className={`${errors.mobilePrefix ? 'invalid' : ''} ${
                    !errors.mobilePrefix && touchedFields.mobilePrefix
                      ? 'valid'
                      : ''
                  }`}
                  {...register('mobilePrefix', { required: true })}
                >
                  <option value=''>--</option>
                  {phoneCodes.map((row, i) => {
                    let optionProps = {}
                    return row !== '' && '+???' ? (
                      <option key={i} {...optionProps} value={row}>
                        {row}
                      </option>
                    ) : (
                      ''
                    )
                  })}
                </select>
              </Col>
              <Col width='68%'>
                <input
                  type='number'
                  defaultValue={user?.mobile_number}
                  className={`${errors.mobileNumber ? 'invalid' : ''} ${
                    !errors.mobileNumber && touchedFields.mobileNumber
                      ? 'valid'
                      : ''
                  }`}
                  {...register('mobileNumber', {
                    required: true,
                  })}
                />
              </Col>
            </Row>
          </Col>
        </Row>

        <SectionHeader>
          <FormattedMessage id='userProfile.addressDetails' />
        </SectionHeader>

        <Row>
          <Col>
            <label htmlFor='address'>
              <FormattedMessage id='userProfile.address' />
            </label>
            <input
              defaultValue={user?.street}
              className={`${errors.address ? 'invalid' : ''} ${
                !errors.address && touchedFields.address ? 'valid' : ''
              }`}
              {...register('address', { required: true })}
            />
          </Col>
          <Col>
            <Row>
              <Col width='68%'>
                <label htmlFor='city'>
                  <FormattedMessage id='userProfile.city' />
                </label>
                <input
                  defaultValue={user?.city}
                  className={`${errors.city ? 'invalid' : ''} ${
                    !errors.city && touchedFields.city ? 'valid' : ''
                  }`}
                  {...register('city', {
                    required: true,
                  })}
                />
              </Col>
              <Col width='32%'>
                <label htmlFor='postCode'>
                  <FormattedMessage id='userProfile.postcode' />
                </label>
                <input
                  defaultValue={user?.postcode}
                  className={`${errors.postCode ? 'invalid' : ''} ${
                    !errors.postCode && touchedFields.postCode ? 'valid' : ''
                  }`}
                  {...register('postCode', {
                    required: true,
                  })}
                />
              </Col>
            </Row>
          </Col>
        </Row>

        <Row>
          <Col>
            <Row>
              <Col>
                <label htmlFor='country'>
                  <FormattedMessage id='userProfile.country' />
                </label>
                <input disabled={true} value={user?.country.name} />
              </Col>
              <Col>
                <label htmlFor='region'>
                  <FormattedMessage id='userProfile.region' />
                </label>
                <input disabled={true} value={user?.region} />
              </Col>
            </Row>
          </Col>
          <Col>
            <Row>
              <Col width='32%'>
                <label htmlFor='currency'>
                  <FormattedMessage id='userProfile.currency' />
                </label>
                <input
                  disabled={true}
                  defaultValue={user?.wallet.currency.short_code}
                />
              </Col>
              <Col />
            </Row>
          </Col>
        </Row>

        <input
          type='submit'
          className='secondary'
          value={intl.formatMessage({ id: 'common.save' })}
        />
      </Form>
    </>
  )
}

export default ProfileDetails
