import React, { useState, useEffect, useCallback } from 'react'
import styled from 'styled-components'
import { FormattedMessage, useIntl } from 'gatsby-plugin-intl'
import { useForm } from 'react-hook-form'

import media from '../../components/css/media'
import { updatePassword } from '../../adapters/user'
import Error from '../../components/common/errorMessage'
import toast from '../../components/common/toast'
import {
  passwordRegex,
  handlePasswordKeyDown,
} from '../../utils/passwordValidation'

const SectionHeader = styled.div`
  line-height: 1.4em;
  font-size: 1.412em;
  font-weight: 600;
  margin: 1rem 0 0.8rem 0;
`

const Form = styled.form`
  input[type='submit'] {
    width: 100% !important;
    margin: 10px 0 0 0;
  }

  ${media.tablet`
    input[type="submit"] {
      width: 30% !important;
    }

  `};
`

const Row = styled.div.attrs((props) => ({
  flexMobile: props.flexMobile || 'column',
}))`
  display: flex;
  gap: 20px;
  flex-direction: ${(props) => props.flexMobile};

  ${media.tablet`
    flex-direction: row;
  `};
`

const Col = styled.div.attrs((props) => ({
  width: props.width || '50%',
}))`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1 1 ${(props) => props.width};
`

const ProfilePassword = () => {
  const intl = useIntl()
  const [error, setError] = useState(false)

  const {
    register,
    handleSubmit,
    formState: { errors, touchedFields },
  } = useForm()

  const onSubmit = useCallback(
    async (data) => {
      if (data.newPassword !== data.confirmPassword) {
        setError(intl.formatMessage({ id: 'common.passwordDoNotMatch' }))
        return
      }

      const model = {
        confirm_password: data.confirmPassword,
        password: data.newPassword,
        old_password: data.oldPassword,
      }

      const response = await updatePassword(model)
      if (response.ok)
        toast.success(
          intl.formatMessage({
            id: 'account.profile.passwordUpdatedSuccessfully',
          })
        )
      if (!response.ok)
        toast.error(intl.formatMessage({ id: 'common.unexpectedError' }))

      switch (response.error.messageCode) {
        case 63:
          setError(intl.formatMessage({ id: 'common.notSamePassword' }))
          break
        case 12:
          setError(intl.formatMessage({ id: 'common.passwordDoNotMatch' }))
          break
        default:
          setError(intl.formatMessage({ id: 'common.unexpectedError' }))
          break
      }
    },
    [intl]
  )

  useEffect(() => {
    handleSubmit(onSubmit)()
  }, [handleSubmit, onSubmit])

  return (
    <>
      <SectionHeader>
        <FormattedMessage id='account.profile.changePassword' />
      </SectionHeader>

      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col>
            <label htmlFor='oldPassword'>
              <FormattedMessage id='account.profile.oldPassword' />
            </label>
            <input
              type='password'
              className={`${
                errors.oldPassword && touchedFields.oldPassword ? 'invalid' : ''
              } ${
                !errors.oldPassword && touchedFields.oldPassword ? 'valid' : ''
              }`}
              {...register('oldPassword', {
                required: true,
                maxLength: 20,
              })}
            />
          </Col>
          <Col />
        </Row>
        <Row>
          <Col>
            <label htmlFor='newPassword'>
              <FormattedMessage id='account.profile.newPassword' />
            </label>
            <input
              type='password'
              className={`${
                errors.newPassword && touchedFields.newPassword ? 'invalid' : ''
              } ${
                !errors.newPassword && touchedFields.newPassword ? 'valid' : ''
              }`}
              onKeyDown={handlePasswordKeyDown}
              {...register('newPassword', {
                required: true,
                maxLength: 20,
                pattern: {
                  value: passwordRegex,
                },
              })}
            />
          </Col>
          <Col>
            <label htmlFor='confirmPassword'>
              <FormattedMessage id='common.confirmPassword' />
            </label>
            <input
              type='password'
              className={`${
                errors.confirmPassword && touchedFields.confirmPassword
                  ? 'invalid'
                  : ''
              } ${
                !errors.confirmPassword && touchedFields.confirmPassword
                  ? 'valid'
                  : ''
              }`}
              onKeyDown={handlePasswordKeyDown}
              {...register('confirmPassword', {
                required: true,
                maxLength: 20,
                pattern: {
                  value: passwordRegex,
                },
              })}
            />
          </Col>
        </Row>

        <small>
          <FormattedMessage id='common.passwordHint' />
        </small>

        {error && <Error text={`${error}`} />}

        <input
          type='submit'
          className='secondary'
          value={intl.formatMessage({ id: 'common.submit' })}
        />
      </Form>
    </>
  )
}

export default ProfilePassword
