import React from 'react'
import styled from 'styled-components'
import { FormattedMessage, useIntl } from 'gatsby-plugin-intl'
import { useForm } from 'react-hook-form'
import media from '../../components/css/media'
import { requestEmailChange } from '../../adapters/user'
import toast from '../../components/common/toast'

const SectionHeader = styled.div`
  line-height: 1.4em;
  font-size: 1.412em;
  font-weight: 600;
  margin: 1rem 0 0.8rem 0;
`

const Form = styled.form`
  input[type='submit'] {
    width: 100% !important;
    margin: 0;
  }

  ${media.tablet`
    input[type="submit"] {
      width: 30% !important;
    }
  `};
`

const Row = styled.div.attrs((props) => ({
  flexMobile: props.flexMobile || 'column',
}))`
  display: flex;
  gap: 20px;
  flex-direction: ${(props) => props.flexMobile};

  ${media.tablet`
    flex-direction: row;
  `};
`

const Col = styled.div.attrs((props) => ({
  width: props.width || '50%',
}))`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1 1 ${(props) => props.width};
`

const ProfileEmail = () => {
  const intl = useIntl()

  const {
    register,
    handleSubmit,
    formState: { errors, touchedFields },
  } = useForm()

  const onSubmit = async (data) => {
    const response = await requestEmailChange(data.email)
    if (response.ok)
      toast.success(
        intl.formatMessage({
          id: 'account.profile.emailChangeRequestedSuccessfully',
        })
      )
    if (!response.ok)
      toast.error(intl.formatMessage({ id: 'common.unexpectedError' }))
  }

  return (
    <>
      <SectionHeader>
        <FormattedMessage id='account.profile.changeYourEmail' />
      </SectionHeader>

      <p>
        <FormattedMessage id='account.profile.changeEmailInstructions' />
      </p>

      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col>
            <label htmlFor='email'>
              <FormattedMessage id='account.profile.newEmailAddress' />
            </label>
            <input
              type='email'
              className={`${errors.email ? 'invalid' : ''} ${
                !errors.email && touchedFields.email ? 'valid' : ''
              }`}
              {...register('email', { required: true })}
            />
          </Col>
          <Col>
            <input
              type='submit'
              className='secondary'
              value={intl.formatMessage({ id: 'common.submit' })}
            />
          </Col>
        </Row>
      </Form>
    </>
  )
}

export default ProfileEmail
