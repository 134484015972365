import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { FormattedMessage, navigate } from 'gatsby-plugin-intl'

import AccountWrapper from './wrapper'
import ProfileDetails from './profileDetails'
import ProfilePassword from './profilePassword'
import ProfileEmail from './profileEmail'

import media, { sizes } from '../../components/css/media'
import useModal from '../../hooks/useModal'
import Modal from '../../components/common/modal'

import ChevronLeftIcon from '../../images/icon_chevron_left_white.svg'
import TimesIcon from '../../images/icon_times_white.svg'

const Title = styled.div`
  text-align: center;
  font-weight: 300;
  background-color: #000;
  color: #fff;
  line-height: 50px;
  padding: 0 1rem;

  > img {
    float: left;
    margin: 0.9rem 0 0 0;

    &:last-child {
      float: right;
    }
  }

  ${media.tablet`
    font-size: 1.5em;
    font-weight: 600;
    color: #000;
    line-height: initial;
    padding: 0;
    background-color: #fff;
    text-align: left;

    > img {
      display: none;
    }
  `};
`

const Tabs = styled.div`
  justify-content: center;
  display: flex;
`

const Content = styled.div`
  margin: 2rem 0;
  padding: 0 1rem;

  ${media.tablet`
    padding: 0;
  `};
`

const Ul = styled.ul`
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;

  ${media.tablet`
    width: auto;
  `};
`

const Li = styled.li`
  cursor: pointer;
  box-sizing: border-box;
  padding: 1rem 0;
  width: 33.33%;
  text-align: center;
  font-size: 0.9em;
  flex: 1;

  &:hover,
  &.active {
    border-bottom: 3px solid #da0000;
    padding-bottom: calc(1rem - 3px);
    color: #da0000;
  }

  ${media.tablet`
    margin: 0 .5rem;
    padding: 1rem;
    font-size: 1em;
    width: auto;
    flex: auto;

    &:hover,
    &.active {
      color: #000;
    }
  `};
`

const Profile = () => {
  const breakpoint = sizes.tablet
  const { closeModal } = useModal()
  const [width, setWidth] = useState(
    typeof window !== 'undefined' ? window.innerWidth : breakpoint
  )

  const onResizeWindow = () => {
    setWidth(window.innerWidth)
  }

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', onResizeWindow)
    }

    return () => {
      window.removeEventListener('resize', onResizeWindow)
    }
  })

  const handleClose = (action) => {
    closeModal()
    action === 'close' ? navigate('/casino') : navigate('/account')
  }

  return (
    <AccountWrapper>
      {width < breakpoint && (
        <Modal
          theme='account'
          title={''}
          isOpen={width < breakpoint}
          onClose={handleClose}
        >
          <ProfileContent closeModal={handleClose} />
        </Modal>
      )}

      {width > breakpoint && <ProfileContent />}
    </AccountWrapper>
  )
}

const ProfileContent = ({ closeModal }) => {
  const [tab, setTab] = useState(0)

  return (
    <>
      <Title>
        <img
          src={ChevronLeftIcon}
          alt='Left'
          width={12}
          onClick={() => closeModal('back')}
        />
        <FormattedMessage id='account.profile.header' />
        <img
          src={TimesIcon}
          alt='Close'
          width={12}
          onClick={() => closeModal('close')}
        />
      </Title>
      <Tabs>
        <Ul>
          <Li className={tab === 0 ? 'active' : ''} onClick={() => setTab(0)}>
            <FormattedMessage id='account.profile.details' />
          </Li>
          <Li className={tab === 1 ? 'active' : ''} onClick={() => setTab(1)}>
            <FormattedMessage id='account.profile.password' />
          </Li>
          <Li className={tab === 2 ? 'active' : ''} onClick={() => setTab(2)}>
            <FormattedMessage id='userProfile.changeEmail' />
          </Li>
        </Ul>
      </Tabs>
      <Content>
        {tab === 0 && <ProfileDetails />}
        {tab === 1 && <ProfilePassword />}
        {tab === 2 && <ProfileEmail />}
      </Content>
    </>
  )
}

export default Profile
